import React, { useState, useEffect, useCallback } from 'react';
import * as XLSX from 'xlsx';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Excelanalysisapp.css';
import { 
  BarChart, LineChart, AreaChart, ScatterChart, ComposedChart,Label,
  Bar, Line, Area, Scatter, XAxis, YAxis, 
  CartesianGrid, Tooltip, Legend,
  ReferenceArea,
  ResponsiveContainer 
} from 'recharts';
const ExcelAnalysisApp = () => {
  const [file, setFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [dataSummary, setDataSummary] = useState(null);
  const [dataPreview, setDataPreview] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState('');
  const [fullData, setFullData] = useState([]);
  const [pivotConfig, setPivotConfig] = useState({
    rows: [],
    cols: [],
    vals: [],
    aggregatorName: "Count",
    rendererName: "Table"
  });
  //const [chartData, setChartData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [uniqueListConfig, setUniqueListConfig] = useState({
    categorical: [],
    numerical: [],
    date: []
  });
  //const [fullDataChartType, setFullDataChartType] = useState('bar');
  const [pivotChartType, setPivotChartType] = useState('bar');
  const [pivotChartData, setPivotChartData] = useState([]);
  const [uniqueList, setUniqueList] = useState([]);
  const [correlationMatrix, setCorrelationMatrix] = useState([]);
  const [correlationVariables, setCorrelationVariables] = useState({ x: '', y: '' });
  const [correlationData, setCorrelationData] = useState([]);
  const [correlationCoefficient, setCorrelationCoefficient] = useState(null);
  const [boxPlotData1, setBoxPlotData1] = useState([]);
  const [boxPlotData2, setBoxPlotData2] = useState([]);
  const [boxPlotVariables, setBoxPlotVariables] = useState({ set1: [], set2: [] });
  const [trendlineData, setTrendlineData] = useState([]);
  //const [trendlineVariables, setTrendlineVariables] = useState({ date: '', value: '' });
  const [trendlineVariables, setTrendlineVariables] = useState({ x: '', y: '' });
  const [trendlineType, setTrendlineType] = useState('linear');
  const [trendlineEquation, setTrendlineEquation] = useState('');
  const [rSquared, setRSquared] = useState(null);
  const [confidenceInterval, setConfidenceInterval] = useState(95);

  const handleTrendlineVariableChange = (axis, variable) => {
    setTrendlineVariables(prev => {
      const newVariables = { ...prev, [axis]: variable };
     if (newVariables.x && newVariables.y) {
        calculateTrendline();
      }
      return newVariables;
    });
  };
  
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0];
      const rows = jsonData.slice(1);

      const columnsInfo = headers.map(header => ({
        name: header,
        type: inferColumnType(rows.map(row => row[headers.indexOf(header)]))
      }));

      const processedData = rows.map(row => 
        Object.fromEntries(headers.map((header, i) => [header, row[i]]))
      );

      setColumns(columnsInfo);
      setFullData(processedData);
      setDataPreview(processedData.slice(0, 5));

      setDataSummary({
        rowCount: rows.length,
        columnCount: headers.length,
        numericColumns: columnsInfo.filter(col => col.type === 'number').map(col => col.name),
        categoricalColumns: columnsInfo.filter(col => col.type === 'string').map(col => col.name),
        dateColumns: columnsInfo.filter(col => col.type === 'date').map(col => col.name)
      });
    };
    reader.readAsArrayBuffer(uploadedFile);
  };

  const inferColumnType = (values) => {
    const nonNullValues = values.filter(v => v != null);
    if (nonNullValues.every(v => !isNaN(v))) return 'number';
    if (nonNullValues.every(v => !isNaN(Date.parse(v)))) return 'date';
    return 'string';
  };

  const handleOperationSelect = (operation) => {
    setSelectedOperation(operation);
  };

  const handleColumnSelect = (columnType, columnName) => {
    setUniqueListConfig(prev => ({
      ...prev,
      [columnType]: prev[columnType].includes(columnName)
        ? prev[columnType].filter(col => col !== columnName)
        : [...prev[columnType], columnName]
    }));
  };

  const createUniqueList = () => {
    const selectedColumns = [...uniqueListConfig.categorical, ...uniqueListConfig.numerical, ...uniqueListConfig.date];
    if (selectedColumns.length === 0) return;

    const uniqueValues = new Set();
    fullData.forEach(row => {
      const combinedValue = selectedColumns.map(col => row[col]).join(' - ');
      uniqueValues.add(combinedValue);
    });

    setUniqueList(Array.from(uniqueValues));
  };

  const updateChartData = useCallback((config) => {
    if (fullData.length === 0) return;

  const pivotResult = fullData.reduce((acc, row) => {
    const rowKey = config.rows.map(r => row[r]).join('-');
    const colKey = config.cols.map(c => row[c]).join('-');
    const value = config.vals.length > 0 ? Number(row[config.vals[0]]) || 0 : 1;

    if (!acc[rowKey]) acc[rowKey] = {};
    if (!acc[rowKey][colKey]) acc[rowKey][colKey] = 0;
    acc[rowKey][colKey] += value;

    return acc;
  }, {});

  const newChartData = Object.entries(pivotResult).map(([rowKey, colValues]) => ({
    name: rowKey,
    ...colValues
  }));

  setPivotChartData(newChartData);
}, [fullData]);

  const handlePivotConfigChange = (config) => {
    setPivotConfig(config);
    updateChartData(config);
  };

  const handleCorrelationVariableChange = (axis, variable) => {
    setCorrelationVariables(prev => ({ ...prev, [axis]: variable }));
  };

  const calculateCorrelationMatrix = useCallback(() => {
    const numericColumns = columns.filter(col => col.type === 'number').map(col => col.name);
    const matrix = [];

    for (let i = 0; i < numericColumns.length; i++) {
      matrix[i] = [];
      for (let j = 0; j < numericColumns.length; j++) {
        const xValues = fullData.map(row => parseFloat(row[numericColumns[i]]));
        const yValues = fullData.map(row => parseFloat(row[numericColumns[j]]));
        
        const correlation = calculateCorrelation(xValues, yValues);
        matrix[i][j] = correlation;
      }
    }

    setCorrelationMatrix(matrix);
  }, [columns, fullData]);

  const calculateCorrelation = (xValues, yValues) => {
    const n = xValues.length;
    const sumX = xValues.reduce((a, b) => a + b, 0);
    const sumY = yValues.reduce((a, b) => a + b, 0);
    const sumXY = xValues.reduce((sum, x, i) => sum + x * yValues[i], 0);
    const sumX2 = xValues.reduce((sum, x) => sum + x * x, 0);
    const sumY2 = yValues.reduce((sum, y) => sum + y * y, 0);

    const r = (n * sumXY - sumX * sumY) / 
              (Math.sqrt(n * sumX2 - sumX * sumX) * Math.sqrt(n * sumY2 - sumY * sumY));
    return r;
  };

  const calculateBoxPlotData = useCallback((variables, setData) => {
    const boxPlotData = variables.map(column => {
      const values = fullData.map(row => parseFloat(row[column])).filter(val => !isNaN(val)).sort((a, b) => a - b);
      const q1 = values[Math.floor(values.length / 4)];
      const median = values[Math.floor(values.length / 2)];
      const q3 = values[Math.floor(values.length * 3 / 4)];
      const iqr = q3 - q1;
      const min = Math.max(values[0], q1 - 1.5 * iqr);
      const max = Math.min(values[values.length - 1], q3 + 1.5 * iqr);

      return {
        name: column,
        min,
        q1,
        median,
        q3,
        max
      };
    });

    setData(boxPlotData);
  }, [fullData]);
  const handleBoxPlotVariableChange = (set, variables) => {
    setBoxPlotVariables(prev => ({ ...prev, [set]: variables }));
  };

  
  const calculateTrendline = useCallback(() => {
    if (trendlineVariables.x && trendlineVariables.y) {
      const data = fullData.map(row => ({
        x: row[trendlineVariables.x],
        y: parseFloat(row[trendlineVariables.y])
      })).filter(point => !isNaN(point.y));
  
      // Sort data by x value
      data.sort((a, b) => {
        if (typeof a.x === 'string') {
          return new Date(a.x) - new Date(b.x);
        }
        return a.x - b.x;
      });
  
      let trendlineFunc, equation, r2;
  
      // Convert x to numeric if it's a date
      const numericData = data.map((point, index) => ({
        x: typeof point.x === 'string' ? index : point.x,
        y: point.y
      }));
  
      switch (trendlineType) {
        case 'linear':
          [trendlineFunc, equation, r2] = calculateLinearTrendline(numericData);
          break;
        case 'exponential':
          [trendlineFunc, equation, r2] = calculateExponentialTrendline(numericData);
          break;
        case 'logarithmic':
          [trendlineFunc, equation, r2] = calculateLogarithmicTrendline(numericData);
          break;
        case 'polynomial':
          [trendlineFunc, equation, r2] = calculatePolynomialTrendline(numericData, 2);
          break;
        default:
          [trendlineFunc, equation, r2] = calculateLinearTrendline(numericData);
      }
  
      const trendlinePoints = data.map((point, index) => ({
        x: point.x,
        y: point.y,
        trendline: trendlineFunc(typeof point.x === 'string' ? index : point.x)
      }));
  
      setTrendlineData(trendlinePoints);
      setTrendlineEquation(equation);
      setRSquared(r2);
    }
  }, [fullData, trendlineVariables, trendlineType]);

  useEffect(() => {
    if (trendlineVariables.x && trendlineVariables.y) {
      calculateTrendline();
    }
  }, [trendlineVariables, trendlineType, calculateTrendline]);

  const calculateLinearTrendline = (data) => {
    const n = data.length;
    const sumX = data.reduce((sum, point) => sum + point.x, 0);
    const sumY = data.reduce((sum, point) => sum + point.y, 0);
    const sumXY = data.reduce((sum, point) => sum + point.x * point.y, 0);
    const sumX2 = data.reduce((sum, point) => sum + point.x ** 2, 0);

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);
    const intercept = (sumY - slope * sumX) / n;

    const trendlineFunc = (x) => slope * x + intercept;
    const equation = `y = ${slope.toFixed(4)}x + ${intercept.toFixed(4)}`;

    const yMean = sumY / n;
    const ssTotal = data.reduce((sum, point) => sum + (point.y - yMean) ** 2, 0);
    const ssResidual = data.reduce((sum, point) => sum + (point.y - trendlineFunc(point.x)) ** 2, 0);
    const r2 = 1 - (ssResidual / ssTotal);

    return [trendlineFunc, equation, r2];
  };

  const calculateExponentialTrendline = (data) => {
    const n = data.length;
    const sumX = data.reduce((sum, point) => sum + point.x, 0);
    const sumLnY = data.reduce((sum, point) => sum + Math.log(point.y), 0);
    const sumXLnY = data.reduce((sum, point) => sum + point.x * Math.log(point.y), 0);
    const sumX2 = data.reduce((sum, point) => sum + point.x ** 2, 0);

    const a = Math.exp((sumLnY * sumX2 - sumX * sumXLnY) / (n * sumX2 - sumX ** 2));
    const b = (n * sumXLnY - sumX * sumLnY) / (n * sumX2 - sumX ** 2);

    const trendlineFunc = (x) => a * Math.exp(b * x);
    const equation = `y = ${a.toFixed(4)}e^(${b.toFixed(4)}x)`;

    const yMean = data.reduce((sum, point) => sum + point.y, 0) / n;
    const ssTotal = data.reduce((sum, point) => sum + (point.y - yMean) ** 2, 0);
    const ssResidual = data.reduce((sum, point) => sum + (point.y - trendlineFunc(point.x)) ** 2, 0);
    const r2 = 1 - (ssResidual / ssTotal);

    return [trendlineFunc, equation, r2];
  };

  const calculateLogarithmicTrendline = (data) => {
    const n = data.length;
    const sumLnX = data.reduce((sum, point) => sum + Math.log(point.x), 0);
    const sumY = data.reduce((sum, point) => sum + point.y, 0);
    const sumLnXY = data.reduce((sum, point) => sum + Math.log(point.x) * point.y, 0);
    const sumLnX2 = data.reduce((sum, point) => sum + Math.log(point.x) ** 2, 0);

    const b = (n * sumLnXY - sumLnX * sumY) / (n * sumLnX2 - sumLnX ** 2);
    const a = (sumY - b * sumLnX) / n;

    const trendlineFunc = (x) => a + b * Math.log(x);
    const equation = `y = ${a.toFixed(4)} + ${b.toFixed(4)}ln(x)`;

    const yMean = sumY / n;
    const ssTotal = data.reduce((sum, point) => sum + (point.y - yMean) ** 2, 0);
    const ssResidual = data.reduce((sum, point) => sum + (point.y - trendlineFunc(point.x)) ** 2, 0);
    const r2 = 1 - (ssResidual / ssTotal);

    return [trendlineFunc, equation, r2];
  };

  const calculatePolynomialTrendline = (data, degree) => {
    // This is a simplified polynomial regression for degree 2
    // For higher degrees, you'd need to implement a more complex algorithm
    const n = data.length;
    const sumX = data.reduce((sum, point) => sum + point.x, 0);
    const sumY = data.reduce((sum, point) => sum + point.y, 0);
    const sumX2 = data.reduce((sum, point) => sum + point.x ** 2, 0);
    const sumX3 = data.reduce((sum, point) => sum + point.x ** 3, 0);
    const sumX4 = data.reduce((sum, point) => sum + point.x ** 4, 0);
    const sumXY = data.reduce((sum, point) => sum + point.x * point.y, 0);
    const sumX2Y = data.reduce((sum, point) => sum + point.x ** 2 * point.y, 0);

    const matrix = [
      [n, sumX, sumX2],
      [sumX, sumX2, sumX3],
      [sumX2, sumX3, sumX4]
    ];
    const vector = [sumY, sumXY, sumX2Y];

    // Solve the system of linear equations
    const [a, b, c] = solveLinearSystem(matrix, vector);

    const trendlineFunc = (x) => a + b * x + c * x ** 2;
    const equation = `y = ${c.toFixed(4)}x² + ${b.toFixed(4)}x + ${a.toFixed(4)}`;

    const yMean = sumY / n;
    const ssTotal = data.reduce((sum, point) => sum + (point.y - yMean) ** 2, 0);
    const ssResidual = data.reduce((sum, point) => sum + (point.y - trendlineFunc(point.x)) ** 2, 0);
    const r2 = 1 - (ssResidual / ssTotal);

    return [trendlineFunc, equation, r2];
  };

  const solveLinearSystem = (matrix, vector) => {
    // Gaussian elimination (simplified for 3x3 matrix)
    for (let i = 0; i < 3; i++) {
      for (let j = i + 1; j < 3; j++) {
        const factor = matrix[j][i] / matrix[i][i];
        for (let k = i; k < 3; k++) {
          matrix[j][k] -= factor * matrix[i][k];
        }
        vector[j] -= factor * vector[i];
      }
    }

    const result = new Array(3);
    for (let i = 2; i >= 0; i--) {
      result[i] = vector[i];
      for (let j = i + 1; j < 3; j++) {
        result[i] -= matrix[i][j] * result[j];
      }
      result[i] /= matrix[i][i];
    }

    return result;
  };


  useEffect(() => {
    if (fullData.length > 0 && columns.length > 0) {
      calculateBoxPlotData(boxPlotVariables.set1, setBoxPlotData1);
      calculateBoxPlotData(boxPlotVariables.set2, setBoxPlotData2);
    }
  }, [fullData, columns, boxPlotVariables, calculateBoxPlotData]);

  //useEffect(() => {
    //calculateTrendlineData();
  //}, [trendlineVariables, calculateTrendlineData]);

  const CustomBoxPlot = ({ x, y, width, height, payload }) => {
    const { min, q1, median, q3, max } = payload;
    const middleBarWidth = width * 0.5;
    
    return (
      <g>
        <line x1={x + width / 2} y1={y + height - (min - q1)} x2={x + width / 2} y2={y + height - (max - q1)} stroke="black" />
        <rect x={x} y={y + height - (q3 - q1)} width={width} height={q3 - q1} fill="#8884d8" stroke="black" />
        <line x1={x} y1={y + height - (median - q1)} x2={x + width} y2={y + height - (median - q1)} stroke="black" strokeWidth="2" />
        <line x1={x + width / 2 - middleBarWidth / 2} y1={y + height - (min - q1)} x2={x + width / 2 + middleBarWidth / 2} y2={y + height - (min - q1)} stroke="black" />
        <line x1={x + width / 2 - middleBarWidth / 2} y1={y + height - (max - q1)} x2={x + width / 2 + middleBarWidth / 2} y2={y + height - (max - q1)} stroke="black" />
      </g>
    );
  };
  
  const renderBoxPlot = (data, title) => (
    <div className="chart-container">
      <h3>{title}</h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart layout="vertical" data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip />
          <Legend />
          <Bar dataKey="median" fill="#8884d8" shape={<CustomBoxPlot />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  const renderTrendline = () => (
    <div className="chart-container">
    <h3>Trendline Analysis</h3>
    <div className="trendline-controls">
      <div>
        <label htmlFor="xVariable">X Variable: </label>
        <select
          id="xVariable"
          value={trendlineVariables.x}
          onChange={(e) => handleTrendlineVariableChange('x', e.target.value)}
        >
          <option value="">Select X Variable</option>
          {columns.map(col => (
            <option key={col.name} value={col.name}>{col.name}</option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="yVariable">Y Variable: </label>
        <select
          id="yVariable"
          value={trendlineVariables.y}
          onChange={(e) => handleTrendlineVariableChange('y', e.target.value)}
        >
          <option value="">Select Y Variable</option>
          {columns.filter(col => col.type === 'number').map(col => (
            <option key={col.name} value={col.name}>{col.name}</option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="trendlineType">Trendline Type: </label>
        <select
          id="trendlineType"
          value={trendlineType}
          onChange={(e) => {
            setTrendlineType(e.target.value);
            if (trendlineVariables.x && trendlineVariables.y) {
            calculateTrendline();
            }
          }}
        >
          <option value="linear">Linear</option>
          <option value="exponential">Exponential</option>
          <option value="logarithmic">Logarithmic</option>
          <option value="polynomial">Polynomial (2nd degree)</option>
        </select>
      </div>
    </div>
    {trendlineData.length > 0 && (
      <>
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <CartesianGrid />
            <XAxis 
              dataKey="x" 
              name={trendlineVariables.x} 
              type={columns.find(col => col.name === trendlineVariables.x)?.type === 'date' ? 'category' : 'number'}
              domain={['auto', 'auto']}
              label={{ value: trendlineVariables.x, position: 'bottom' }}
            />
            <YAxis 
              dataKey="y" 
              name={trendlineVariables.y}
              label={{ value: trendlineVariables.y, angle: -90, position: 'left' }}
            />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Data" data={trendlineData} fill="#8884d8" />
            <Line name="Trendline" type="monotone" dataKey="trendline" stroke="#ff7300" dot={false} activeDot={false} />
          </ScatterChart>
        </ResponsiveContainer>
        <div className="trendline-info">
          <p>Equation: {trendlineEquation}</p>
          <p>R² = {rSquared.toFixed(4)}</p>
        </div>
      </>
    )}

    </div>
  );
  
  const renderChart = (data, chartType, setChartType, title) => (
    <div className="chart-container">
      <h3>{title}</h3>
      <div className="chart-controls">
        <label htmlFor={`chartType-${title}`}>Chart Type: </label>
        <select 
          id={`chartType-${title}`}
          value={chartType} 
          onChange={(e) => setChartType(e.target.value)}
        >
          <option value="bar">Bar Chart</option>
          <option value="line">Line Chart</option>
          <option value="area">Area Chart</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        {chartType === 'bar' ? (
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(data[0] || {}).filter(key => key !== 'name').map((key, index) => (
              <Bar key={key} dataKey={key} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
            ))}
          </BarChart>
        ) : chartType === 'line' ? (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(data[0] || {}).filter(key => key !== 'name').map((key, index) => (
              <Line key={key} type="monotone" dataKey={key} stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
            ))}
          </LineChart>
        ) : (
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(data[0] || {}).filter(key => key !== 'name').map((key, index) => (
              <Area key={key} type="monotone" dataKey={key} fill={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
            ))}
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  );

  const updateScatterPlot = useCallback(() => {
    if (correlationVariables.x && correlationVariables.y) {
      const data = fullData.map(row => ({
        x: parseFloat(row[correlationVariables.x]),
        y: parseFloat(row[correlationVariables.y])
      })).filter(point => !isNaN(point.x) && !isNaN(point.y));

      setCorrelationData(data);

      const xValues = data.map(point => point.x);
      const yValues = data.map(point => point.y);
      const r = calculateCorrelation(xValues, yValues);
      setCorrelationCoefficient(r);
    }
  }, [correlationVariables, fullData]);

  useEffect(() => {
    if (fullData.length > 0 && columns.length > 0) {
      calculateCorrelationMatrix();
    }
  }, [fullData, columns, calculateCorrelationMatrix]);

  useEffect(() => {
    updateScatterPlot();
  }, [correlationVariables, updateScatterPlot]);

  const getCorrelationColor = (value) => {
    const hue = ((1 - value) * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
  };

  const renderCorrelationMatrix = () => {
    const numericColumns = columns.filter(col => col.type === 'number').map(col => col.name);
    return (
      <div className="correlation-matrix">
        <h3>Correlation Matrix</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              {numericColumns.map(col => <th key={col}>{col}</th>)}
            </tr>
          </thead>
          <tbody>
            {correlationMatrix.map((row, i) => (
              <tr key={i}>
                <th>{numericColumns[i]}</th>
                {row.map((value, j) => (
                  <td 
                    key={j} 
                    style={{backgroundColor: getCorrelationColor(Math.abs(value))}}
                  >
                    {value.toFixed(2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderScatterPlot = () => (
    <div className="chart-container">
      <h3>Correlation Scatter Plot</h3>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name={correlationVariables.x} />
          <YAxis type="number" dataKey="y" name={correlationVariables.y} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter name="Data" data={correlationData} fill="#8884d8" />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );

  const operations = [
    //{ name: 'Create Pivot Table', value: 'pivot' },
    //{ name: 'Create Chart', value: 'chart' },
    //{ name: 'Generate VBA Code', value: 'vba' },
   // { name: 'Create Macro', value: 'macro' },
    { name: 'Correlate Data', value: 'correlation' },
    { name: 'Forecast Data', value: 'forecasts' }
  ];

  return (
    <div className="excel-analysis-app">
      <h1>Excel Analysis Web App</h1>
      
      <div className="file-upload-section">
        <h2>File Upload</h2>
        <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" />
        {file && <p>File uploaded: {file.name}</p>}
      </div>

      <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
        <TabList>
          <Tab>Data Overview</Tab>
          <Tab>Unique List</Tab>
          <Tab>Pivot Table & Charts</Tab>          
          <Tab>Operations</Tab>
        </TabList>

        <TabPanel>
          <Tabs>
            <TabList>
              <Tab>Data Preview</Tab>
              <Tab>Data Summary</Tab>
              <Tab>Column Information</Tab>
            </TabList>
            <TabPanel>
              {/* Data Preview content */}
              {dataPreview.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      {columns.map(col => <th key={col.name}>{col.name}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {dataPreview.map((row, index) => (
                      <tr key={index}>
                        {columns.map(col => <td key={col.name}>{row[col.name]}</td>)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </TabPanel>
            <TabPanel>
              {/* Data Summary content */}
              {dataSummary && (
                <ul>
                  <li>Total Rows: {dataSummary.rowCount}</li>
                  <li>Total Columns: {dataSummary.columnCount}</li>
                  <li>Numeric Columns: {dataSummary.numericColumns.join(', ')}</li>
                  <li>Categorical Columns: {dataSummary.categoricalColumns.join(', ')}</li>
                  <li>Date Columns: {dataSummary.dateColumns.join(', ')}</li>
                </ul>
              )}
            </TabPanel>
            <TabPanel>
              {/* Column Information content */}
              {columns.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {columns.map((col, index) => (
                      <tr key={index}>
                        <td>{col.name}</td>
                        <td>{col.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </TabPanel>
          </Tabs>
        </TabPanel>
        
        <TabPanel>
          <h2>Create Unique List</h2>
          <Tabs>
            <TabList>
              <Tab>Categorical</Tab>
              <Tab>Numerical</Tab>
              <Tab>Date</Tab>
            </TabList>
            <TabPanel>
              {columns.filter(col => col.type === 'string').map((col) => (
                <label key={col.name}>
                  <input 
                    type="checkbox"
                    checked={uniqueListConfig.categorical.includes(col.name)}
                    onChange={() => handleColumnSelect('categorical', col.name)}
                  />
                  {col.name}
                </label>
              ))}
            </TabPanel>
            <TabPanel>
              {columns.filter(col => col.type === 'number').map((col) => (
                <label key={col.name}>
                  <input 
                    type="checkbox"
                    checked={uniqueListConfig.numerical.includes(col.name)}
                    onChange={() => handleColumnSelect('numerical', col.name)}
                  />
                  {col.name}
                </label>
              ))}
            </TabPanel>
            <TabPanel>
              {columns.filter(col => col.type === 'date').map((col) => (
                <label key={col.name}>
                  <input 
                    type="checkbox"
                    checked={uniqueListConfig.date.includes(col.name)}
                    onChange={() => handleColumnSelect('date', col.name)}
                  />
                  {col.name}
                </label>
              ))}
            </TabPanel>
          </Tabs>
          <button onClick={createUniqueList}>Create Unique List</button>
          {uniqueList.length > 0 && (
            <div>
              <h3>Unique Values:</h3>
              <ul>
                {uniqueList.map((value, index) => (
                  <li key={index}>{value}</li>
                ))}
              </ul>
            </div>
          )}
        </TabPanel>

        <TabPanel>
        <h2>Pivot Table & Charts</h2>
        {fullData.length > 0 ? (
      <>
        <PivotTableUI
          data={fullData}
          onChange={handlePivotConfigChange}
          {...pivotConfig}
        />
        {renderChart(pivotChartData, pivotChartType, setPivotChartType, "Pivot Data Chart")}
      </>
    ) : (
      <p>Upload a file and configure the pivot table to see results</p>
    )}
        </TabPanel>        

        <TabPanel>
        <h2>Select Operation</h2>
        <div className="operations-grid">
      {operations.map((op) => (
        <button
          key={op.value}
          onClick={() => handleOperationSelect(op.value)}
          className={selectedOperation === op.value ? 'selected' : ''}
          disabled={!file}
        >
          {op.name}
        </button>
      ))}      
    </div>
    {selectedOperation === 'correlation' && (
      <div className="operation-result">
        <h3>Correlation Analysis</h3>
        {renderCorrelationMatrix()}
        <div>
          <label htmlFor="xVariable">X Variable: </label>
          <select
            id="xVariable"
            value={correlationVariables.x}
            onChange={(e) => handleCorrelationVariableChange('x', e.target.value)}
          >
            <option value="">Select X Variable</option>
            {columns.filter(col => col.type === 'number').map(col => (
              <option key={col.name} value={col.name}>{col.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="yVariable">Y Variable: </label>
          <select
            id="yVariable"
            value={correlationVariables.y}
            onChange={(e) => handleCorrelationVariableChange('y', e.target.value)}
          >
            <option value="">Select Y Variable</option>
            {columns.filter(col => col.type === 'number').map(col => (
              <option key={col.name} value={col.name}>{col.name}</option>
            ))}
          </select>
        </div>
        {correlationCoefficient !== null && (
          <p>Correlation Coefficient: {correlationCoefficient.toFixed(4)}</p>
        )}
        {renderScatterPlot()}
      </div>
    )}
    {selectedOperation === 'forecasts' && (
      <div className="operation-result">
      <h3>Forecast Data</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '48%' }}>
          <h4>Box Plot Set 1</h4>
          <select
            multiple
            value={boxPlotVariables.set1}
            onChange={(e) => handleBoxPlotVariableChange('set1', Array.from(e.target.selectedOptions, option => option.value))}
            style={{ width: '100%', height: '100px' }}
          >
            {columns.filter(col => col.type === 'number').map(col => (
              <option key={col.name} value={col.name}>{col.name}</option>
            ))}
          </select>
          {renderBoxPlot(boxPlotData1, "Box Plot Set 1")}
        </div>
        <div style={{ width: '48%' }}>
          <h4>Box Plot Set 2</h4>
          <select
            multiple
            value={boxPlotVariables.set2}
            onChange={(e) => handleBoxPlotVariableChange('set2', Array.from(e.target.selectedOptions, option => option.value))}
            style={{ width: '100%', height: '100px' }}
          >
            {columns.filter(col => col.type === 'number').map(col => (
              <option key={col.name} value={col.name}>{col.name}</option>
            ))}
          </select>
          {renderBoxPlot(boxPlotData2, "Box Plot Set 2")}
        </div>
      </div>
      <div>
        <h4>Trendline Analysis</h4>
        <div>
          <label htmlFor="xVariable">X Variable: </label>
          <select
            id="xVariable"
            value={trendlineVariables.x}
            onChange={(e) => handleTrendlineVariableChange('x', e.target.value)}
          >
            <option value="">Select X Variable</option>
            {columns.map(col => (
              <option key={col.name} value={col.name}>{col.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="yVariable">Y Variable: </label>
          <select
            id="yVariable"
            value={trendlineVariables.y}
            onChange={(e) => handleTrendlineVariableChange('y', e.target.value)}
          >
            <option value="">Select Y Variable</option>
            {columns.filter(col => col.type === 'number').map(col => (
              <option key={col.name} value={col.name}>{col.name}</option>
            ))}
          </select>
        </div>
        {trendlineData.length > 0 && renderTrendline()}
      </div>
    </div>
    )}
    </TabPanel>    
</Tabs>
</div>
  );
};
export default ExcelAnalysisApp;